// eslint:disable
import { useCallback, useEffect, useRef } from 'react';
import { Element } from 'react-scroll';
import Career from '../sections/career';
import Contact from '../sections/contact';
import Funding from '../sections/funding';
import InitialDisclaimer from "../sections/disclaimer";
import Publications from '../sections/publications';
import '../styles/main.scss';
import { translations } from '../data/translations';
import { useSelector } from 'react-redux';

function MainPage(props) {
  const language = useSelector(state => state.taskReducer.language);
  const homeRef = useRef();
  const careerRef = useRef();
  const publicationRef = useRef();
  const fundingRef = useRef();
  const eventRef = useRef();
  const contactRef = useRef();

  const onscroll = useCallback(() => {
    if (
      careerRef?.current?.getBoundingClientRect()?.top - 150 > 0
    ) {
      props.activateSection("home");
    } else if (
      careerRef?.current?.getBoundingClientRect()?.top - 150 < 0 &&
      publicationRef?.current?.getBoundingClientRect()?.top - 150 > 0
    ) {
      props.activateSection("career");
    } else if (
      publicationRef?.current?.getBoundingClientRect()?.top - 150 < 0 &&
      fundingRef?.current?.getBoundingClientRect()?.top - 150 > 0
    ) {
      props.activateSection("pub");
    } else if (
      fundingRef?.current?.getBoundingClientRect()?.top - 150 < 0 &&
      eventRef?.current?.getBoundingClientRect()?.top - 150 > 0
    ) {
      props.activateSection("funding");
    } else if (
      eventRef?.current?.getBoundingClientRect()?.top - 150 < 0 &&
      contactRef?.current?.getBoundingClientRect()?.top - 150 > 0
    ) {
      props.activateSection("event");
    } else if (contactRef?.current?.getBoundingClientRect()?.top - 150 < 0) {
      props.activateSection("contact");
    }
  }, [props]);

  useEffect(() => {
    window.addEventListener('scroll', onscroll);
    return () => {
      window.removeEventListener('scroll', onscroll);
    };
  }, [onscroll]);

  return (
    <div>
      <div className={'section__image__home'} />
      <div className={'section__wrapper'}>
        <Element className={'section section-home'} name={'home'} containerId={'home'}>
          <div ref={homeRef} style={{ position: "relative", width: "100%" }}>
            <div className={"title-page"}>{translations.MAIN_TITLE[language]}</div>
          </div>
        </Element>
        <Element className={'section section-disclaimer'} name={'disclaimer'}>
          <div ref={homeRef} style={{ position: "relative", width: "100%" }}>
            <InitialDisclaimer />
          </div>
        </Element>
        <Element className={'section'} name={'career'} containerId={'career'}>
          <div ref={careerRef}>
            <Career />
          </div>
        </Element>
        <Element
          className={'section'}
          name={'publications'}
          containerId={'publications'}
        >
          <div ref={publicationRef}>
            <Publications />
          </div>
        </Element>
        <Element className={'section'} name={'funding'} containerId={'funding'}>
          <div ref={fundingRef}>
            <Funding />
          </div>
        </Element>
        <Element className={'section'} name={'events'} containerId={'events'}>
          <div ref={eventRef} style={{width: "100%", maxWidth: "800px" }}>
            <h2>{translations.EVENTS[language]?.toUpperCase()}</h2>
            <h3 style={{ marginTop: "30px" }}>Coherence Correlations Complexity Seminar</h3>
            <iframe 
              src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23009695&ctz=Europe%2FWarsaw&showPrint=0&showCalendars=1&showTz=1&showTabs=1&showDate=1&showNav=1&showTitle=0&mode=AGENDA&src=Y181ZDJjYTk1YjFmYjdiMWEzNTBkMzNjNDM4Nzc0NjhmZGEyOGRkNTRlZDljMGY1ZGY2ZTVlMWEyMDU4MzRiNDRhQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23D50000" 
              style={{ width: "100%" }} 
              width="800" 
              height="600" 
              frameBorder="0" 
              scrolling="no"
              title="calendar"
            >
            </iframe>
          </div>
        </Element>
        <Element
          className={'section'}
          name={'contact'}
          containerId={'contacts'}
        >
          <div ref={contactRef}>
            <Contact />
          </div>
        </Element>
      </div>
    </div>
  );
}

export default MainPage;
