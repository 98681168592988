
import { changeLanguage } from "../redux/actions";
import "../styles/main.scss";
import { useDispatch, useSelector } from "react-redux";

function CountryChooser(props) {
  const dispatch = useDispatch();
  const languageSelected = useSelector(state => state.taskReducer.language);

  const onChangeLanguage = (language) => {
    dispatch(changeLanguage(language));
  };

  return (
    <div 
      className={"country_chooser"}
      onClick={props.changeHeader}
      style={{
        position: "fixed",
        left: 20,
      }}
    >
      <div
        onClick={onChangeLanguage.bind(this, "en")}
        className={"language_wrapper"}
        style={{outline: languageSelected === "en" ? "3px solid black" : ""}}
      >
        <img src="../assets/flag_england.png"/>
      </div>
      <div
        onClick={onChangeLanguage.bind(this, "pl")}
        className={"language_wrapper"}
        style={{outline: languageSelected === "pl" ? "3px solid black" : ""}}
      >
        <img src="../assets/flag_poland.svg"/>
      </div>
    </div>
  );
}

export default CountryChooser;
