import { useSelector } from 'react-redux';
import { funding } from '../data/funding';
import { translations } from '../data/translations';

const Funding = () => {
  const language = useSelector(state => state.taskReducer.language);

  const listEvents = funding.events.map((event) => {
    return (
      <li className={'publication-list-element'}>
        <p className={'publication-link'}><b>{`${event[`date_${language}`]}: `}<span><a style={{color: "black"}} target="_blank" href={`${event.link_event ? event.link_event : undefined}`}>{`${event[`event_${language}`]}`}</a></span></b></p> 
        <b>{`${translations.TITLE[language]}: `} </b><i>{`${event[`title_${language}`]}`}</i><br></br><b>Speaker:</b> {event.speaker} <a target="_blank" href={`${event.link_pdf ? event.link_pdf : undefined}`}><br></br><b style={{color: "black"}}>{`${translations.SLIDES[language]}`}</b></a>
      </li>
    );
  });

  return (
    <div>
      <h2>{translations.DISSEMINATIONS[language].toUpperCase()}</h2>
      <h3>{translations.INVITED_PRESENTATIONS[language]}</h3>
      <ul>
        {listEvents}
      </ul>
    </div>
  );
};

export default Funding;
