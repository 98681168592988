export const disclaimer = {
        par_1: {
            en: "Liquid water is made of a large amount of microscopic particles (H2O molecules). However, from our perspective, it is nothing else than a continuum entity with the properties of what we usually call a uid. Within its properties, for example, we nd the propagation of waves.", 
            pl: "Woda w stanie ciekłym składa się z dużej ilości mikroskopijnych cząsteczek (H2O molekuł). Ale można ją traktować jako substancję ciągłą o właściwościach, które zwykle de niują płyn. Wśród jej właściwości znajdujemy na przykład propagację fal.", 
        }, 
        par_2: {
            en: "The physical theory describing the uid dynamics is called hydrodynamics. The success of hydrodynamics to describe liquid water relies on the fact that is not necessary to know the microscopic details of the system. Instead, it is enough to assume that all the microscopic particles behave in a collective way. Therefore, the problem of nding the velocity of all these particles, is reduced to solving for the local velocity of the uid, and thermodynamic variables as local temperature and density.",
            pl: "Teoria zyczna opisująca dynamikę płynów nazywa się hydrodynamiką. Sukces hydrodynamiki w opisie ciekłej wody opiera się na tym, że do jej opisu nie jest konieczna znajomość mikroskopijnych szczegółów układu; wystarczy założyć, że mikroskopijne cząsteczki zachowują się w sposób kolektywny, co redukuje problem znalezienia wszystkich ich prędkości do znajomości prędkości w każdym punkcie płynu oraz pewnych zmiennych termodynamicznych w zależności od lokalnej temperatury i gęstości.", 
        },
        par_3: {
            en: "The goal of this project is to describe the hydrodynamic properties of electrons inside certain recently discovered materials, usually called topological semimetals. What is peculiar about the ow of electrons inside these materials, is the fact that exotic quantum effects allows to transport electrons inside a wire made of this material without loosing energy. The understanding of such properties will settle the ground for the design of more ef cient devices to store energy, and might serve for the construction of quantum computers.",
            pl: "Celem tego projektu jest opis właściwości hydrodynamicznych elektronów wewnątrz pewnych niedawno odkrytych materiałów, nazywanych półmetalami topologicznymi. Charakterystyczne dla przepływu elektronów wewnątrz tych materiałów jest to, że egzotyczne efekty kwantowe pozwalają na transport elektronów wewnątrz drutu wykonanego z tego materiału bez utraty energii. Zrozumienie tych właściwości da podstawy do budowy bardziej wydajnych urządzeń do magazynowania energii i może również posłużyć do konstrukcji komputerów kwantowych.",
        },    
        par_4: {
            en: "The origin of such exotic properties relies on certain similarities of the mathematical models describing the physical properties of the electrons inside the material and the ones describing the interaction of highly energetic particles, like the particles produced in colliders as the LHC in Geneva.",
            pl: "Pochodzenie tych egzotycznych właściwości opiera się na pewnych podobieństwach modeli matematycznych opisujących właściwości zyczne elektronów wewnątrz materiału oraz modeli opisujących oddziaływanie cząstek o wysokiej energii, takich jak te produkowane w zderzaczach cząstek elementarnych, np. w genewskim LHC.",
        },
        par_5: {
            en: "The mathematical framework used to describe the high energy physics is called relativistic quantum eld theory.",
            pl: "Formalizm matematyczny zyki wysokich energii nazywa się relatywistyczną kwantową teorią pola.",
        },
        par_6: { 
            en: "In some sense, learning about the properties of such materials will provide us not only with important technological advances, but will help us acquire a deeper understanding of the fundamental laws of physics responsible for the structure of our universe.", 
            pl: "W pewnym sensie poznanie właściwości rozważanych materiałów zapewni nam nie tylko istotny postęp technologiczny, lecz  także pomoże na głębsze zrozumienie podstawowych praw zyki odpowiedzialnych za strukturę wszechświata.",
        },
        par_7: {
            en: "Based on the similarities to the relativistic subatomic particles, we propose in this project to apply these techniques in the description of those apparently disconnected systems, like electrons inside a material. Within these tools we will use the holographic principle which relates black holes with the dynamics of quantum particles in hydrodynamic regime.", 
            pl: "Opierając się na analogii do relatywistycznych cząstek subatomowych, proponujemy w tym projekcie zastosowanie technik teorii pola do opisu pozornie odmiennego układów, czyli elektronów wewnątrz materiału. Wykorzystamy zasadę hologra czną, która łączy zykę czarnej dziury oraz dynamikę cząstek kwantowych w reżimie hydrodynamicznym.",
        },
        par_8: {    
            en: "The holographic principle, usually called gauge/gravity duality, surprisingly relates the dynamics of the events horizon of a black hole with the theories of uid dynamics.",
            pl: "Zasada hologra czna, zwana też dualizmem cechowania/grawitacji, w zaskakujący sposób powiązuje dynamikę horyzontu zdarzeń czarnej dziury z teoriami dynamiki płynów. Innymi słowy, czarne dziury zachowują się jak ciecz.",
        },   
        par_9: {
            en: "In other words, black holes behaves as a uid. In particular, holography becomes a useful tool when the interaction between particles is so strong, that all the standard computational techniques fail.", 
            pl: "W szczególności, hologra a okazuje się użytecznym narzędziem, gdy oddziaływanie pomiędzy cząstkami jest tak silne, że standardowe techniki obliczeniowe zawodzą.",
        },
        par_10: {
            en: "Finally, with this project we expect to predict the existence of new electronic transport properties in topological materials, and settle the ground for important technological advances in electronics.",
            pl: "Podsumowując, w ramach tego projektu spodziewamy się przewidzieć istnienie nowych właściwości transportu elektronowego w materiałach topologicznych oraz stworzyć podstawy dla istotnego postępu technologicznego w elektronice.",
        },
        par_11: {
            en: "Top: Water in a state out of equilibrium, where the propagation of waves can be seen. Bottom: first image of a black hole, using Event Horizon Telescope. The luminous ring corresponds with the light bending due to the strong gravitational right outside the horizon. Credit: Event Horizon Telescope Collaboration.",
            pl: "U góry: Woda poza stanem równowagi; widoczne jest rozchodzenie się fal. U dołu: pierwszy obraz czarnej dziury uzyskany przy użyciu Teleskopu Horyzontu Zdarzeń (ang. Event Horizon Telescope). Świetlisty pierścień odpowiada ugięciu światła spowodowanemu silną grawitacją tuż za horyzontem. Źródło: Event Horizon Telescope Collaboration.",
        },
        
    };

     
