export const members = [
    {
        id: "FRA",
        completeName: "Francisco Peña-Benítez",
        imgSrc: "../assets/Fran.jpeg",
        linkPublications: "https://scholar.google.com/citations?user=o9ESTiAAAAAJ&hl=en",
        academicDegrees: [
            {
                date_en: "July 2013",
                date_pl: "Lip. 2013",
                description_en: "Ph.D at Univ. Autónoma de Madrid. Thesis: Anomalous hydrodynamics, from weak to strong coupling. Advisor: Prof. Karl Landsteiner.",
                description_pl: "Ph.D at Univ. Autónoma de Madrid. Thesis: Anomalous hydrodynamics, from weak to strong coupling. Advisor: Prof. Karl Landsteiner.",
            },
            {
                date_en: "January 2007",
                date_pl: "Sty. 2007",
                description_en: "M.Sc. in Physics at Univ. Simón Bolívar Caracas (Venezuela). Thesis: Path formulation of non-commutative gauge theories. Advisor: Prof. Alvaro Restuccia.",
                description_pl: "M.Sc. in Physics at Univ. Simón Bolívar Caracas (Venezuela). Thesis: Path formulation of non-commutative gauge theories. Advisor: Prof. Alvaro Restuccia.",
            },
            {
                date_en: "July 2004",
                date_pl: "Lip. 2004",
                description_en: "Physics Degree at Universidad Central de Venezuela, Caracas (Venezuela). Thesis: Geometric equations for the Wilson loop in abelian gauge theories. Advisor: Prof. Lorenzo Leal.",
                description_pl: "Physics Degree at Universidad Central de Venezuela, Caracas (Venezuela). Thesis: Geometric equations for the Wilson loop in abelian gauge theories. Advisor: Prof. Lorenzo Leal.",
            },
        ],
        professionalEmployment: [
            {
                date_en: "2017 – 2021",
                date_pl: "2017 – 2021",
                description_en: "Postdoctoral position. Max-Planck-Institut für Physik komplexer Systeme, Dresden (Germany).",
                description_pl: "Postdoctoral position. Max-Planck-Institut für Physik komplexer Systeme, Dresden (Germany).",
            },
            {
                date_en: "2015 – 2017",
                date_pl: "2015 – 2017",
                description_en: "Postdoctoral position. INFN - Sezione di Perugia, Perugia (Italy).",
                description_pl: "Postdoctoral position. INFN - Sezione di Perugia, Perugia (Italy).",
            },
            {
                date_en: "2013 – 2015",
                date_pl: "2013 – 2015",
                description_en: "Postdoctoral position. University of Crete, Heraklion (Greece)",
                description_pl: "Postdoctoral position. University of Crete, Heraklion (Greece)",
            },
            {
                date_en: "2009 – 2013",
                date_pl: "2009 – 2013",
                description_en: "Research Assistant Theoretical Phys. dept., Univ. Autónoma de Madrid, Madrid (Spain). Under the support of the government of the region of Madrid.",
                description_pl: "Research Assistant Theoretical Phys. dept., Univ. Autónoma de Madrid, Madrid (Spain). Under the support of the government of the region of Madrid.",
            },
        ],
        researchVisit: [
            {
                date_en: "Nov. 2018",
                date_pl: "Lis. 2018",
                description_en: "Two weeks Research Visit Beihang University, Beijing (China)",
                description_pl: "Dwutygodniowa wizyta badawcza na Uniwersytecie Beihang w Pekinie (Chiny)",
            },
            {
                date_en: "Feb. 2014",
                date_pl: "Lut. 2014",
                description_en: "One month Research Visit to Simons Center for geometry and physics. Stony brook (USA)",
                description_pl: "Miesięczna wizyta badawcza w Simons Center w zakresie geometrii i fizyki. Stony brook (USA)",
            },
            {
                date_en: "Nov. 2013",
                date_pl: "Lis. 2013",
                description_en: "Three weeks Research Visit IFT UAM/CSIC, Madrid (Spain)",
                description_pl: "Trzytygodniowa wizyta badawcza IFT UAM/CSIC, Madryt (Hiszpania)",
            },
            {
                date_en: "Oct. 2011",
                date_pl: "Paz. 2011",
                description_en: "Three month Research Stay in MPI (Werner-Heisenberg- Inst.)",
                description_pl: "Three month Research Stay in MPI (Werner-Heisenberg- Inst.)",
            },
        ],
    },
    {
        id: "PAT",
        completeName: "Patricio Salgado-Rebolledo",
        imgSrc: "../assets/Patricio.jpeg",
        linkPublications: "https://scholar.google.com/citations?hl=en&user=d5rQ1yIAAAAJ&view_op=list_works",
        academicDegrees: [
            {
                date_en: "Mar. 2010 - Oct. 2015",
                date_pl: "Mar. 2010 - Oct. 2015",
                description_en: "Ph.D at University of Concepcion/Free University of Brussels. Thesis: Symplecitc structure of Constrained System: Gribov ambiguity and classical duals for 3D gravity. Advisor: Prof. Dr. Jorge Zanelli (CECs) and Prof. Dr. Glenn Barnich (ULB).",
                description_pl: "Ph.D at University of Concepcion/Free University of Brussels. Thesis: Symplecitc structure of Constrained System: Gribov ambiguity and classical duals for 3D gravity. Advisor: Prof. Dr. Jorge Zanelli (CECs) and Prof. Dr. Glenn Barnich (ULB).",
            },
            {
                date_en: "Mar 2012 - Apr. 2013",
                date_pl: "Mar 2012 - Apr. 2013",
                description_en: "M.Sc. in Physics at Universidad de Concepción & CECs (Chile). Thesis: Gribov ambiguity in spaces with non-trivial topology. Advisor: Prof. Dr. Fabrizio Canfora.",
                description_pl: "M.Sc. in Physics at Universidad de Concepción & CECs (Chile). Thesis: Gribov ambiguity in spaces with non-trivial topology. Advisor: Prof. Dr. Fabrizio Canfora.",
            },
            {
                date_en: "Mar. 2006 - Dec. 2009",
                date_pl: "Mar. 2006 - Dec. 2009",
                description_en: "B. Sc. Physics - Universidad de Concepción (Chile).",
                description_pl: "B. Sc. Physics - Universidad de Concepción (Chile).",
            },
        ],
        professionalEmployment: [
            {
                date_en: "2023 – present",
                date_pl: "2023 – present",
                description_en: "Postdoctoral Researcher at Vienna Univeristy of Technology, Austria",
                description_pl: "Postdoctoral Researcher at Vienna Univeristy of Technology, Austria",
            },
            {
                date_en: "2021 – 2023",
                date_pl: "2021 – 2023",
                description_en: "Postdoctoral Researcher at Wrocław University of Science and Technology, Poland",
                description_pl: "Postdoctoral Researcher at Wrocław University of Science and Technology, Poland",
            },
            {
                date_en: "2015 – 2017",
                date_pl: "2015 – 2017",
                description_en: "Postdoctoral position. INFN - Sezione di Perugia, Perugia (Italy).",
                description_pl: "Postdoctoral position. INFN - Sezione di Perugia, Perugia (Italy).",
            },
            {
                date_en: "2020 – 2021",
                date_pl: "2020 – 2021",
                description_en: "Postdoctoral Researcher at Free University of Brussels, Belgium.",
                description_pl: "Postdoctoral Researcher at Free University of Brussels, Belgium.",
            },
            {
                date_en: "2018 – 2020",
                date_pl: "2018 – 2020",
                description_en: "Postdoctoral Researcher at Institute of Physics, Catholic University of Valparaíso, Chile.",
                description_pl: "Postdoctoral Researcher at Institute of Physics, Catholic University of Valparaíso, Chile.",
            },
            {
                date_en: "2015 – 2018",
                date_pl: "2015 – 2018",
                description_en: "Postdoctoral Research Fellow at Adolfo Ibañez University, Chile (FONDECYT grant N◦ 3160581).",
                description_pl: "Postdoctoral Research Fellow at Adolfo Ibañez University, Chile (FONDECYT grant N◦ 3160581).",
            },
        ],
        researchVisit: [
            {
                date_en: "Mar. 2020 - Sept. 2020",
                date_pl: "Mar. 2020 - Sept. 2020",
                description_en: "Visitor at School of Physics and Astronomy, University of Leeds, United Kingdom",
                description_pl: "Visitor at School of Physics and Astronomy, University of Leeds, United Kingdom",
            },
        ],
    }
];