export const translations = {
    MAIN_TITLE: {
        en: "Field Theories, Gravity and Topology in Condensed Matter Systems",
        pl: "Teorie pola, grawitacja i topologia w systemach materii skondensowanej",
    },
    TITLE: {
        en: "Title",
        pl: "Tytuł",
    },
    ACADEMIC_DEGREES: {
        en: "Academic degrees",
        pl: "Stopnie naukowe",
    },
    MEMBERS: {
        en: "Members",
        pl: "Członkowie",
    },
    SLIDES: {
        en: "Slides",
        pl: "Slajdy",
    },
    DISSEMINATIONS: {
        en: "Dissemination",
        pl: "Upowszechniania",
    },
    INVITED_PRESENTATIONS: {
        en: "Invited presentations and lectures",
        pl: "Zaproszone prezentacje i prelekcje",
    },
    PROFESSIONAL_EMPLOY: {
        en: "Professional employment",
        pl: "Zatrudnienie zawodowe",
    },
    RESEARCH_VISITS: {
        en: "Research visits",
        pl: "Wizyty badawcze",
    },
    HOME: {
        en: "Home",
        pl: "Strona główna",
    },
    PUBLICATIONS: {
        en: "Publications",
        pl: "Publikacje",
    },
    EVENTS: {
        en: "Events",
        pl: "Wydarzenia",
    },
    CONTACTS: {
        en: "Contacts",
        pl: "Kontakty",
    },
    OFFICE: {
        en: "Office",
        pl: "Biuro",
    },
    POLAND: {
        en: "Poland",
        pl: "Polska",
    },
}