export const funding = {
  events: [
    {
      date_en: "Nov. 2023",
      date_pl: "Lis. 2023",
      title_en: "Fractons gauge theory vs Gravity",
      title_pl: "Fractons gauge theory vs Gravity",
      event_en: "HoloTube, The Applied Holography Webinars Network",
      event_pl: "HoloTube, The Applied Holography Webinars Network",
      speaker: "Francisco Peña-Benítez",
      link_event: "https://projects.ift.uam-csic.es/holotube/",
      link_pdf: "/assets/Holotube.pdf",
    },
    {
      date_en: "Nov. 2023",
      date_pl: "Lis. 2023",
      title_en: "Fracton gauge fields from a higher-dimensional gravity",
      title_pl: "Fracton gauge fields from a higher-dimensional gravity",
      event_en: "Seminar at Technische Universität Wien (Austria)",
      event_pl: "Seminar at Technische Universität Wien (Austria)",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/",
    },
    {
      date_en: "Oct. 2023",
      date_pl: "Paz. 2023",
      title_en: "Fracton gauge fields from a higher-dimensional gravity",
      title_pl: "Fracton gauge fields from a higher-dimensional gravity",
      event_en: "Seminar at Jagiellonian Univesity (Poland)",
      event_pl: "Seminar at Jagiellonian Univesity (Poland)",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/TalkCracow.pdf",
    },
    {
      date_en: "Sept. 2023",
      date_pl: "Wrz. 2023",
      title_en: "Fractons Hydrodynamics",
      title_pl: "Fractons Hydrodynamics",
      event_en: "CMD30-FISMAT",
      event_pl: "CMD30-FISMAT",
      speaker: "Francisco Peña-Benítez",
      link_event: "https://eventi.cnism.it/cmd30-fismat/conference-program",
      link_pdf: "/assets/Milan.pdf",
    },
    {
      date_en: "June 2023",
      date_pl: "Cze. 2023",
      title_en: "Non-relativistic geometry and higher-spins in the fractional quantum Hall effect",
      title_pl: "Non-relativistic geometry and higher-spins in the fractional quantum Hall effect",
      event_en: "Seminar at Technische Universität Wien (Austria)",
      event_pl: "Seminar at Technische Universität Wien (Austria)",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/TalkVienna.pdf",
    },
    {
      date_en: "May 2023",
      date_pl: "Maj 2023",
      title_en: "The gauge principle and the fundamental interactions of nature",
      title_pl: "The gauge principle and the fundamental interactions of nature",
      event_en: "Lecture for the Nabla Physics Students Society, Wrocław University of Science and Technology (Poland)",
      event_pl: "Lecture for the Nabla Physics Students Society, Wrocław University of Science and Technology (Poland)",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/TalkNabla.pdf",
    },
    {
      date_en: "April 2023",
      date_pl: "Kwi. 2023",
      title_en: "Non-relativistic geometry and higher-spins in the fractional quantum Hall effect",
      title_pl: "Non-relativistic geometry and higher-spins in the fractional quantum Hall effect",
      event_en: "WULB/Solvay workshop Progress on gravitational physics - 45 years of Belgian-Chilean collaboration",
      event_pl: "WULB/Solvay workshop Progress on gravitational physics - 45 years of Belgian-Chilean collaboration",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/TalkBrussels.pdf",
    },
    {
      date_en: "Feb. 2023",
      date_pl: "Lut. 2023",
      title_en: "Spacetime symmetries and Fractons",
      title_pl: "Spacetime symmetries and Fractons",
      event_en: "Seminar at Université de Tours (France)",
      event_pl: "Seminar at Université de Tours (France)",
      speaker: "Francisco Peña-Benítez",
      link_event: "",
      link_pdf: "/assets/Bruxelles.pdf",
    },
    {
      date_en: "Feb. 2023",
      date_pl: "Lut. 2023",
      title_en: "Fracton gauge fields in curved space from higher dimensions",
      title_pl: "Fracton gauge fields in curved space from higher dimensions",
      event_en: "Seminar at Dublin Institute for Advanced Studies (Ireland)",
      event_pl: "Seminar at Dublin Institute for Advanced Studies (Ireland)",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/TalkDublin.pdf",
    },
    {
      date_en: "Feb. 2023",
      date_pl: "Lut. 2023",
      title_en: "Fracton gauge fields in curved space from higher dimensions",
      title_pl: "Fracton gauge fields in curved space from higher dimensions",
      event_en: "Seminar at University of Groningen (Netherlands)",
      event_pl: "Seminar at University of Groningen (Netherlands)",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/TalkGroningen.pdf",
    },
    {
      date_en: "Feb. 2023",
      date_pl: "Lut. 2023",
      title_en: "Fracton gauge fields in curved space from higher dimensions",
      title_pl: "Fracton gauge fields in curved space from higher dimensions",
      event_en: "Seminar at University of Leeds (UK)",
      event_pl: "Seminar at University of Leeds (UK)",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/TalkLeeds.pdf",
    },
    {
      date_en: "Dec. 2022",
      date_pl: "Gru. 2022",
      title_en: "Non-Lorentzian symmetries and Lie algebra expansions",
      title_pl: "Non-Lorentzian symmetries and Lie algebra expansions",
      event_en: "(Virtual) Seminar at University of Murcia (Spain)",
      event_pl: "(Virtual) Seminar at University of Murcia (Spain)",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/TalkMurcia.pdf",
    },
    {
      date_en: "June 2022",
      date_pl: "Cze. 2022",
      title_en: "Spacetime symmetries and Fractons",
      title_pl: "Spacetime symmetries and Fractons",
      event_en: "Recent Developments in Strongly-Correlated Quantum Matter",
      event_pl: "Recent Developments in Strongly-Correlated Quantum Matter",
      speaker: "Francisco Peña-Benítez",
      link_event: "https://indico.ectstar.eu/event/163/contributions/3518/",
      link_pdf: "/assets/Bruxelles.pdf",
    },
    {
      date_en: "June 2022",
      date_pl: "Cze. 2022",
      title_en: "Chern-Simons forms, 3D gravity and the fractional quantum Hall effect",
      title_pl: "Chern-Simons forms, 3D gravity and the fractional quantum Hall effect",
      event_en: "Seminar at Wrocław University of Science and Technology (Poland)",
      event_pl: "Seminar at Wrocław University of Science and Technology (Poland)",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/TalkWroclaw.pdf",
    },
    {
      date_en: "May 2022",
      date_pl: "Maj 2022",
      title_en: "Symmetric Gauge fields",
      title_pl: "Symmetric Gauge fields",
      event_en: "Emergent Hydrodynamics in Condensed Matter and High-energy Physics",
      event_pl: "Emergent Hydrodynamics in Condensed Matter and High-energy Physics",
      speaker: "Francisco Peña-Benítez",
      link_event: "https://www.pks.mpg.de/hydro22",
      link_pdf: "/assets/Quito.pdf",
    },
    {
      date_en: "May 2022",
      date_pl: "Maj 2022",
      title_en: "Spacetime symmetries and Fractons",
      title_pl: "Spacetime symmetries and Fractons",
      event_en: "Seminar at Université Libre de Bruxelles (Belgium)",
      event_pl: "Seminar at Université Libre de Bruxelles (Belgium)",
      speaker: "Francisco Peña-Benítez",
      link_event: "",
      link_pdf: "/assets/Bruxelles.pdf",
    },
    {
      date_en: "Feb. 2022",
      date_pl: "Lut. 2022",
      title_en: "An effective description of the Moore-Read fractional quantum Hall state from the Nappi-Witten supersymmetry",
      title_pl: "An effective description of the Moore-Read fractional quantum Hall state from the Nappi-Witten supersymmetry",
      event_en: "(Virtual) Seminar at Kadanoff Center for Theoretical Physics (US)",
      event_pl: "(Virtual) Seminar at Kadanoff Center for Theoretical Physics (US)",
      speaker: "Patricio Salgado-Rebolledo",
      link_event: "",
      link_pdf: "/assets/",
    },
    {
      date_en: "Nov. 2021",
      date_pl: "Lis. 2021",
      title_en: "Fractons electrodynamics and geometry",
      title_pl: "Fractons electrodynamics and geometry",
      event_en: "Seminar at San Francisco de Quito University (Ecuador)",
      event_pl: "Seminar at San Francisco de Quito University (Ecuador)",
      speaker: "Francisco Peña-Benítez",
      link_event: "",
      link_pdf: "/assets/Quito.pdf",
    },

  ],
};
