export const publications = [
  {
    title_en: 'Dissipative fracton superfluids',
    title_pl: 'Dissipative fracton superfluids',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=o9ESTiAAAAAJ&sortby=pubdate&citation_for_view=o9ESTiAAAAAJ:7BrZ7Jt4UNcC",
    authors: 'A. Głódkowski, F. Peña-Benítez, P. Surówka',
    publicatedIn: 'arXiv: 2401.01877',
  },
  {
    title_en: 'Fracton gauge fields from higher dimensional gravity',
    title_pl: 'Fracton gauge fields from higher dimensional gravity',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=o9ESTiAAAAAJ&sortby=pubdate&citation_for_view=o9ESTiAAAAAJ:nRpfm8aw39MC",
    authors: 'F. Peña-Benítez, P. Salgado-Rebolledo',
    publicatedIn: 'arXiv: 2310.12610',
  },
  {
    title_en: 'Hydrodynamics of dipole-conserving fluids',
    title_pl: 'Hydrodynamics of dipole-conserving fluids',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=o9ESTiAAAAAJ&sortby=pubdate&citation_for_view=o9ESTiAAAAAJ:PaBasH6fAo0C",
    authors: 'A. Głódkowski, F. Peña-Benítez, P. Surówka',
    publicatedIn: 'Phys. Review E 107 (2023) 3, 034142',
  },
  {
    title_en: 'Fractons, Symmetric Gauge Fields and Geometry',
    title_pl: 'Fractons, Symmetric Gauge Fields and Geometry',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=o9ESTiAAAAAJ&sortby=pubdate&citation_for_view=o9ESTiAAAAAJ:7Hz3ACDFbsoC",
    authors: 'F. Peña-Benítez',
    publicatedIn: 'Phys. Review Research. 5 (2023) 1, 013101',
  },
  {
    title_en: 'Space-Dependent Symmetries and Fractons',
    title_pl: 'Space-Dependent Symmetries and Fractons',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=o9ESTiAAAAAJ&sortby=pubdate&citation_for_view=o9ESTiAAAAAJ:MpfHP-DdYjUC",
    authors: 'KT Grosvenor, C Hoyos, F. Peña-Benítez, P Surówka',
    publicatedIn: 'Frontiers in Physics 9 (2022) 13',
  },
  {
    title_en: 'A note on the Hamiltonian structure of transgression forms',
    title_pl: 'A note on the Hamiltonian structure of transgression forms',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=d5rQ1yIAAAAJ&sortby=pubdate&citation_for_view=d5rQ1yIAAAAJ:r0BpntZqJG4C",
    authors: 'P. Pais, P. Salgado-Rebolledo, A. Vera',
    publicatedIn: 'Journal of High Energy Physics 2023 (12), 1-25',
  },
  {
    title_en: 'Nonrelativistic spin-3 symmetries in 2+1 dimensions from expanded and extended Nappi-Witten algebras',
    title_pl: 'Nonrelativistic spin-3 symmetries in 2+1 dimensions from expanded and extended Nappi-Witten algebras',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=d5rQ1yIAAAAJ&sortby=pubdate&citation_for_view=d5rQ1yIAAAAJ:_Qo2XoVZTnwC",
    authors: 'R. Caroca, D.M. Peñafiel, P. Salgado-Rebolledo',
    publicatedIn: 'Physical Review D 107 (6), 064034',
  },
  {
    title_en: 'Magnetic Carrollian gravity from the Carroll algebra',
    title_pl: 'Magnetic Carrollian gravity from the Carroll algebra',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=d5rQ1yIAAAAJ&sortby=pubdate&citation_for_view=d5rQ1yIAAAAJ:e5wmG9Sq2KIC",
    authors: 'A. Campoleoni, M. Henneaux, S. Pekar, A. Pérez, P. Salgado-Rebolledo',
    publicatedIn: 'Journal of High Energy Physics 2022 (9), 1-22',
  }, 
  {
    title_en: 'Asymptotic structure of Carrollian limits of Einstein-Yang-Mills theory in four spacetime dimensions',
    title_pl: 'Asymptotic structure of Carrollian limits of Einstein-Yang-Mills theory in four spacetime dimensions',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=d5rQ1yIAAAAJ&sortby=pubdate&citation_for_view=d5rQ1yIAAAAJ:R3hNpaxXUhUC",
    authors: 'O. Fuentealba, M. Henneaux, P. Salgado-Rebolledo, J. Salzer',
    publicatedIn: 'Physical Review D 106 (10), 104047',
  }, 
  {
    title_en: 'Nonrelativistic supergeometry in the Moore-Read fractional quantum Hall state',
    title_pl: 'Nonrelativistic supergeometry in the Moore-Read fractional quantum Hall state',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=d5rQ1yIAAAAJ&sortby=pubdate&citation_for_view=d5rQ1yIAAAAJ:qUcmZB5y_30C",
    authors: 'P. Salgado-Rebolledo, G. Palumbo',
    publicatedIn: 'Physical Review D 106 (6), 065020',
  }, 
  {
    title_en: 'Emerging D massive graviton in graphene-like systems',
    title_pl: 'Emerging D massive graviton in graphene-like systems',
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=d5rQ1yIAAAAJ&sortby=pubdate&citation_for_view=d5rQ1yIAAAAJ:L8Ckcad2t8MC",
    authors: 'P. Salgado-Rebolledo, J.K. Pachos',
    publicatedIn: 'New Journal of Physics 25 (3), 033019',
  }, 
];
