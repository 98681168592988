import '../styles/main.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from '../pages/MainPage';
import Grant from '../pages/Grant';
import CountryChooser from './CountryChooser';
import Header from './Header';
import Footer from './Footer';
import LinkComponent from './Link';
import React, { useCallback, useEffect, useState } from 'react';
import BurgerMenu from './BurgerMenu';
import { Link } from 'react-scroll';
import { FiChevronUp } from 'react-icons/fi';
import { translations } from '../data/translations';
import { useSelector } from 'react-redux';

const Scroll = require('react-scroll');

function Main() {
  const language = useSelector(state => state.taskReducer.language);
  const [isBurgerMenu, setBurgerMenu] = useState(false);
  const [headerState, setHeaderState] = useState(false);
  const [section, setSection] = useState('');
  const [offset, setOffset] = useState(window.innerWidth < 769 ? -10 : -90);

  const changeHeader = () => {
    if (window.innerWidth < 769) {
      setHeaderState(!headerState);
    }
  };

  const activateSection = (type) => {
    setSection(type);
  };

  const onresize = useCallback(() => {
    setOffset(window.innerWidth < 769 ? -10 : -90);
  }, []);

  const scrollToTop = () => {
    const scroll = Scroll.animateScroll;
    scroll.scrollToTop({ smooth: true });
  };

  const gototop =
    section !== 'home' ? (
      <Link className={'gototop'} onClick={scrollToTop}>
        <FiChevronUp size={35} className={'icons'} />
      </Link>
    ) : null;

  useEffect(() => {
    window.addEventListener('resize', onresize);
    return () => {
      window.removeEventListener('resize', onresize);
    };
  }, [offset, onresize]);

  const componentMainPage = (
    <div>
      <BurgerMenu
        isBurgerMenu={isBurgerMenu}
        changeHeader={changeHeader}
      />
      <Header
        setBurgerMenu={setBurgerMenu}
        headerState={headerState}
        changeHeader={changeHeader}
      >
        <a href={"https://www.eog.gov.pl"} target={"_blank"} rel="noreferrer" className={"header__link link__logo"}>
          <div className={"eearnorwaylogo"}/>
        </a>
        <LinkComponent
          changeHeader={changeHeader}
          active={section === 'home'}
          target={'home'}
        >
          {translations.HOME[language]}
        </LinkComponent>
        <LinkComponent
          changeHeader={changeHeader}
          active={section === 'career'}
          offset={offset}
          target={'career'}
        >
          {translations.MEMBERS[language]}
        </LinkComponent>
        <LinkComponent
          changeHeader={changeHeader}
          active={section === 'pub'}
          offset={offset}
          target={'publications'}
        >
          {translations.PUBLICATIONS[language]}
        </LinkComponent>
        <LinkComponent
          changeHeader={changeHeader}
          active={section === 'funding'}
          offset={offset}
          target={'funding'}
        >
          {translations.DISSEMINATIONS[language]}
        </LinkComponent>
        <LinkComponent
          changeHeader={changeHeader}
          active={section === 'event'}
          offset={offset}
          target={'events'}
        >
          {translations.EVENTS[language]}
        </LinkComponent>
        <LinkComponent
          changeHeader={changeHeader}
          active={section === 'contact'}
          offset={offset}
          target={'contact'}
        >
          {translations.CONTACTS[language]}
        </LinkComponent>
      </Header>
      <MainPage activateSection={activateSection} />
    </div>
  );

  return (
    <BrowserRouter>
      <div className={'wrapper'}>
        <Routes>
          <Route exact path="/" element={componentMainPage}/>
          <Route path="/grant"/>
          {/* <Route path="/" component={Grant}/> */}
        </Routes>
        {gototop}
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default Main;
