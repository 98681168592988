import { IoDocumentTextOutline } from "react-icons/io5";
import { members } from "../data/members";
import { useSelector } from "react-redux";
import { translations } from "../data/translations";

const Career = () => {
  const language = useSelector(state => state.taskReducer.language);
  const renderRecord = (record, type) => {
    console.log(type);
    if (type === "academic") {
      return <li className={'career-list-element'}><b>{`${record[`date_${language}`]}: `}</b>{record[`description_${language}`]}</li>
    }
    return <li className={'career-list-element'}><b>{`${record[`date_${language}`]}: `}</b>{record[`description_${language}`]}</li>
  };
  const renderMember = (member) => {
    const listAcademicDegrees = member.academicDegrees.map((record) => renderRecord(record, "academic"));
    const listProfessionalEmployment = member.professionalEmployment.map((record) => renderRecord(record, "professional"));
    const listResearchVisit = member.researchVisit.map((record) => renderRecord(record, "research"));
    return (
    <div className={"member-wrapper"}>
      <img className="image-member" src={member.imgSrc} alt={"member"}/>
      <h3>{member.completeName.toUpperCase()}</h3>
      <h3>{translations.ACADEMIC_DEGREES[language]}</h3>
      <ul>{listAcademicDegrees}</ul>
      <h3>{translations.PROFESSIONAL_EMPLOY[language]}</h3>
      <ul>{listProfessionalEmployment}</ul>
      <h3>{translations.RESEARCH_VISITS[language]}</h3>
      <ul>{listResearchVisit}</ul>
      <p style={{display: "flex", alignItems: "center" }}>
        <span style={{marginRight: "10px"}}><IoDocumentTextOutline /></span>
        <span style={{marginRight: "5px"}}>Full list of{' '}</span>
        <a href={member.linkPublications} target="__blank" className="publication-general"
        > <b>{" publications"}</b>
        </a>
      </p>
    </div>
    );
  };
  return (
    <div>
      <h2>{translations.MEMBERS[language].toUpperCase()}</h2>
      <>{members.map(renderMember)}</>
    </div>
  );
};

export default Career;
