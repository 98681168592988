import { publications } from '../data/publications';
import { IoDocumentTextOutline } from "react-icons/io5";
import '../styles/main.scss';
import { useSelector } from 'react-redux';
import { translations } from '../data/translations';

const Publications = () => {
  const language = useSelector(state => state.taskReducer.language);
  const renderAuthors = (authors) => {
    const list = [];
    const authorsArray = authors.split(",");
    authorsArray.map((str, n) => {
      if (str.trim() === "F. Peña-Benítez" || str.trim() === "P. Salgado-Rebolledo" ) {
       list.push(<><span className={'publication-fran'}>{`${str.trim()}`}</span><span>{n === authorsArray.length - 1 ? "" : ", "}</span></>);
      } else {
        list.push(<span>{`${str.trim()}${n === authorsArray.length - 1 ? "" : ", "}`}</span>);
      }
    });
    return (
      <p className={'publication-authors'}>
        {list}
      </p>
    );
  };

  const renderPublication = (pub) => {
    return (
      <li className={'publication-list-element'}>
        <a href={pub.link} target={'__blank'} className={'publication-link'}>
          <p>
            <b>{pub[`title_${language}`]}</b>
          </p>
        </a>
        {renderAuthors(pub.authors)}
        <p className={'publication-publicated'}>{pub.publicatedIn}</p>
      </li>
    );
  };

  return (
    <div>
      <h2 className={'publication-title'}>{translations.PUBLICATIONS[language].toUpperCase()}</h2>
      <ul>{publications.map(renderPublication)}</ul>
    </div>
  );
};

export default Publications;
