import {
  IoMailOutline,
  IoTrailSignOutline,
  IoCallOutline,
} from 'react-icons/io5';
import { BiChair } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { translations } from '../data/translations';

const Contact = () => {
  const language = useSelector(state => state.taskReducer.language);

  return (
    <div>
      <h2>{translations.CONTACTS[language].toUpperCase()}</h2>
      <p className={'contact-line'}>
        <span className={'contact-line-icon'}>
          <IoMailOutline size={22} />
        </span>
        <span className={'contact-line-text'}>
          <a href={'emailto:francisco.pena-benitez@pwr.edu.pl'}>
            francisco.pena-benitez [at] pwr.edu.pl
          </a>
        </span>
      </p>
      <p className={'contact-line'}>
        <span className={'contact-line-icon'}>
          <IoTrailSignOutline size={22} />
        </span>
        <span className={'contact-line-text'}>
          Wybrzeże Wyspiańskiego 27, 50-370 Wrocław ({`${translations.POLAND[language]}`})
        </span>
      </p>
      <p className={'contact-line'}>
        <span className={'contact-line-icon'}>
          <BiChair size={22} />
        </span>
        <span className={'contact-line-text'}>{`${translations.OFFICE[language]}: 218/A1`}</span>
      </p>
      {/* <p className={'contact-line'}>
        <span className={'contact-line-icon'}>
          <IoCallOutline size={22} />
        </span>
        <span className={'contact-line-text'}>XXXXXXX</span>
      </p> */}
      <div className={"contact-icon-group"}>
        <div className={'contact-icon-cell'}>
          <a
            href={'https://www.researchgate.net/profile/Francisco-Pena-Benitez-2'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <div className={'contact-icon researchgate'} />
          </a>
        </div>
        <div className={'contact-icon-cell'}>
          <a
            href={
              'https://scholar.google.com/citations?user=o9ESTiAAAAAJ&hl=it&oi=ao'
            }
            target={'_blank'}
            rel={'noreferrer'}
          >
            <div className={'contact-icon googlescholar'} />
          </a>
        </div>

        <div className={'contact-icon-cell'}>
          <a
            href={'https://inspirehep.net/authors/1064962'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <div className={'contact-icon inspirehep'} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
