import { ActionTypes } from "./costants";

const initialState = {
    language: "pl",
};

const taskReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        default:
            return state;
    }
};

export default taskReducer;

