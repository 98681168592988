  import { useSelector } from "react-redux";
import { disclaimer } from "../data/disclaimer";
import logo from "../images/Norway_grants@4x.png";
  
  const Disclaimer = () => {
    const language = useSelector(state => state.taskReducer.language);
    const par = (n) => disclaimer?.[`par_${n}`]?.[language];

    return (
      <div className={"disclaimer-wrapper"}>
        <div className={"upper-disclaimer-wrapper"}>
          <img className= {"logo"} src={logo} alt={"logo_grant"}/>
          <div className={"disclaimer-slogan"}>
            <div>Working together for a <span className={"disclaimer-slogan-green"}>green</span>, 
            </div><div><span className={"disclaimer-slogan-red"}>competitive</span>, and 
            <span className={"disclaimer-slogan-blue"}> inclusive</span> Europe</div>
          </div>
        </div>
        <hr className={"separator"}/>
          <div className={"lower-disclaimer-wrapper"}>
              <div className={"lower-disclaimer-wrapper-images"}>
                <img src={`/assets/disclaimer_img_1.png`} alt={"disclaimer_1"}/>
                <img src={`/assets/disclaimer_img_2.png`} alt={"disclaimer_2"}/>
                <p>{`${par(11)}`}</p>
              </div>
              <p>{`${par(1)} ${par(2)}`}</p><br/>
              <p>{`${par(3)}`}</p><br/>
              <p>{`${par(4)} ${par(5)} ${par(6)}`}</p><br/>
              <p>{`${par(7)} ${par(8)} ${par(9)}`}</p><br/>
              <p>{`${par(10)}`}</p><br/>
          </div>
      </div>       
    );
  };
  
  export default Disclaimer;
  